.bottomheader-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 10vh;
  text-align: center;
  background-color: rgb(255, 255, 255);
  font-size: 0.9rem;
  font-weight: 100 !important;
}
