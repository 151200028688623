.login {
    text-align: center;
    margin: auto;
    margin-top: 70px;
    display: grid;
    padding-bottom: 70px;
    width: 40%;
}


.login input {
    display: grid;
    font-size: 100%;
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
}


.login button {
    border: none;
    background-color: black;
    color: white;
    border-radius: 4px;
    padding: 1%;
    padding-left: 4%;
    padding-right: 4%;
}

.login button:hover {
    background-color: rgb(53, 53, 53);
}


.errorMessage {
    text-align: center;
    margin-top: 5%;
    border-radius: 6px;
    margin-left: 40%;
    margin-right: 40%;
    border: 1px solid rgb(255, 144, 144);
}


.errorMessage button {
    background-color: rgba(255, 255, 255, 0);
    border: none;
}


.errorMessage button:hover {
    color: rgb(102, 102, 102);
}