.admin-page {
  margin-top: 10%;
}

.admin-singleformview {
  border: 1px solid rgb(192, 192, 192);
  border-radius: 4px;
  width: 80%;
  margin: auto;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .admin-page {
    margin-top: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .admin-page {
    margin-top: 30%;
  }
}
