.register {
    text-align: center;
    margin-top: 10%;
}

.register input {
    display: grid;
    font-size: 104%;
    border: none;
    border-bottom: 1px solid rgb(204, 204, 204);
    margin-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
}


.register button {
    font-size: 104%;
    background-color: rgb(59, 172, 64);
    color: white;
    border: none;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 4px;
}

.register button:hover {
    transition: 1s;
    color: rgb(223, 223, 223);

}