.loading-container {
  position: relative;
  height: 100vh;
}

.loading-spinner {
  background-color: #ffffff;
  width: 300px;
  min-height: 300px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
