html {
  background-color: white;
}

body {
  font-family: "Jost", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: white;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
