.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 6rem;
  background-color: rgba(255, 255, 255, 0);
  z-index: 200;
  transition: ease-out 0.5s;
}

html:not([data-scroll="0"]) .navbar {
  background-color: white;
  transition: ease-in 1s;
  border-bottom: 1px solid rgb(185, 185, 185);
}

@keyframes change {
  from {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 1px solid rgba(201, 201, 201, 0);
  }
  to {
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(201, 201, 201);
  }
}

@keyframes changebackground {
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, 0.39);
  }
}

@keyframes dropdown {
  from {
    margin-bottom: 2%;
  }
  to {
    margin-bottom: -8%;
  }
}

.brand {
  position: fixed;
  z-index: 200;
  height: auto;
  top: 1rem;
  margin-left: 3%;
  z-index: 200;
}

.links {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 10vw;
  min-height: 100%;
}

.navmenubtn {
  position: fixed;
  left: 11120%;
  opacity: 0;
  z-index: 200;
}

.link {
  font-size: 1.3rem !important;
  font-weight: 5 !important;
  margin-left: 2%;
  margin-right: 2%;
  transition: 1s;
  text-decoration-line: underline;
  text-decoration-color: rgb(224, 224, 224);
  text-underline-position: under;
  color: rgb(0, 0, 0) !important;
}

.link:hover {
  text-decoration-color: rgb(112, 112, 112);
  transition: 1s;
}

.logout {
  font: inherit;
  font-size: 90%;
  background-color: rgb(0, 0, 0);
  color: white;
  width: 6%;
  height: 25px;
  border: none;
  border-radius: 4px;
}

.mobile-button {
  background-color: white;
  border: none;
  margin-right: 2vw;
  float: left;
  float: right;
}

@media only screen and (max-width: 900px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 6rem;
    border-bottom: 1px solid rgba(255, 0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
    padding-bottom: 1%;
    z-index: 200;
  }

  .links {
    margin-left: 99999%;
    opacity: 0;
  }
  .navmenubtn {
    position: fixed;
    left: 90%;
    top: 2rem;
    background: none;
    border: none;
    font-size: 190%;
    opacity: 100;
  }

  .brand {
    position: fixed;
    z-index: 200;
    height: auto;
    top: 1.5rem;
    margin-left: 4%;
    z-index: 200;
  }

  .mobile-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 200;
    background-color: white;
    right: 0;
    top: 0;
    height: 100vh;
    font-size: 1.6rem;
    font-family: inherit;
    animation-timing-function: linear;
    animation: slideinfr 0.8s;
    border-left: 1px solid rgb(191, 191, 191);
  }
  .mobile-link {
    width: 35vw;
    color: black;
    margin: 1vh 0 1vh 5vw;
    text-decoration: none;
    border-bottom: 1px solid black;
  }
}

@media only screen and (max-width: 600px) {
  .navmenubtn {
    left: 77% !important;
  }

  .brand {
    position: fixed;
    z-index: 200;
    height: auto;
    top: 1.5rem;
    margin-left: 4%;
    z-index: 200;
  }

  .mobile-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 200;
    background-color: white;
    right: 0;
    top: 0;
    height: 100vh;
    font-size: 1.6rem;
    font-family: inherit;
    animation-timing-function: linear;
    animation: slideinfr 0.8s;
    border-left: 1px solid rgb(191, 191, 191);
  }
  .mobile-link {
    width: 50vw;
    color: black;
    margin: 1vh 0 1vh 5vw;
    text-decoration: none;
    border-bottom: 1px solid black;
  }
}

@keyframes slideinfr {
  from {
    transform: translateX(60vw);
    border: none;
  }
  to {
    transform: translateX(0vw);
    border-left: 1px solid rgb(191, 191, 191);
  }
}
