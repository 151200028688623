.rentalinfo-container {
  width: 70%;
  margin: 14vh auto;

  font-size: 1.1rem;
}

@media only screen and (max-width: 992px) {
  .rentalinfo-container {
    width: 80%;
    margin: 20% auto;
  }
}

@media only screen and (max-width: 600px) {
  .rentalinfo-container {
    width: 80%;
    margin: 30% auto;
  }
}
